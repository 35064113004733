import "./KeyFeatures.scss";
import GradientBox from "../../components/shared/GradientBox";
import { ReactComponent as Cloud } from "../../assets/icons/Cloud.svg";
import { ReactComponent as Globe } from "../../assets/icons/Globe.svg";
import { ReactComponent as Lock } from "../../assets/icons/Lock.svg";
import { ReactComponent as Robot } from "../../assets/icons/Robot.svg";

export default function Hero() {
	return (
		<div className="key-features" id="features">
			<div className="key-features-content content-wrapper-lg">
				<div className="key-features-title">
					<div className="small-title">KEY FEATURES</div>
					<div className="title">What Makes Us Unique</div>
				</div>

				<div className="key-features-cards row">
					<div className="key-feature-item col-6" data-aos="zoom-in">
						<GradientBox className="key-feature-card">
							<div className="kf-icon">
								<Cloud width="30" />
							</div>
							<div className="kf-title">
								Anonymix - Private Relay
							</div>
							<div className="kf-description">
								Anonymix, Proxy Network's Private Relay feature,
								provides an essential layer of security and
								privacy for users by encrypting data
								transmissions and masking IP addresses. This
								sophisticated system ensures complete anonymity
								and protects against online tracking, making
								digital interactions on the blockchain secure
								and private.
							</div>
						</GradientBox>
					</div>

					<div className="key-feature-item col-6" data-aos="zoom-in">
						<GradientBox className="key-feature-card">
							<div className="kf-icon">
								<Lock width="30" />
							</div>
							<div className="kf-title">
								ProxyVPN - Geo-Bypassing
							</div>
							<div className="kf-description">
								ProxyVPN, part of Proxy Network's suite of
								tools, enhances user privacy and security
								through advanced IP masking and data encryption
								while enabling the bypassing of geographical
								restrictions. This powerful feature allows users
								to access global blockchain platforms and
								maintain secure, unrestricted internet
								connectivity, regardless of regional
								limitations.
							</div>
						</GradientBox>
					</div>

					<div className="key-feature-item col-6" data-aos="zoom-in">
						<GradientBox className="key-feature-card">
							<div className="kf-icon">
								<Robot width="30" />
							</div>
							<div className="kf-title">
								Vision - AI Powered FireWall
							</div>
							<div className="kf-description">
								Vision, Proxy Network’s AI-Powered Firewall,
								utilizes advanced machine learning algorithms to
								proactively monitor, detect, and neutralize
								real-time security threats across the network.
								This cutting-edge feature enhances system
								security by automatically identifying and
								responding to potential cyber threats, ensuring
								robust protection for all users.
							</div>
						</GradientBox>
					</div>

					<div className="key-feature-item col-6" data-aos="zoom-in">
						<GradientBox className="key-feature-card">
							<div className="kf-icon">
								<Globe width="30" />
							</div>
							<div className="kf-title">Smart Contract Creator</div>
							<div className="kf-description">
								Proxy Network's Smart Contract Creator
								simplifies the creation of blockchain contracts
								by enabling users to design and deploy smart
								contracts without any coding knowledge. This
								innovative feature leverages AI to generate
								secure and reliable contracts, democratizing
								access to blockchain technology and empowering
								users with easy-to-use tools.
							</div>
						</GradientBox>
					</div>
				</div>
			</div>
		</div>
	);
}
