import GradientBox from "../../components/shared/GradientBox";
import "./Bottom.scss";
import TokenomicsBg from "../../assets/images/tokenomics.png";
import CountUp from "react-countup";
import { Waypoint } from "react-waypoint";
import { useState } from "react";

export default function Bottom() {
	// State to track if the waypoint has been passed
	const [startCounting, setStartCounting] = useState(false);

	return (
		<div className="tokenomics-bottom">
			<div className="tokenomics-bottom-content content-wrapper-lg">
				<Waypoint onEnter={() => setStartCounting(true)} />
				<div className="title">
					TOTAL SUPPLY :{" "}
					<CountUp
						start={99900000}
						end={startCounting ? 100000000 : 0}
						duration={5}
					/>
				</div>

				<div className="tokenomics-cards">
					<div
						className="tokenomics-card-item"
						data-aos="fade-up-right"
					>
						<GradientBox className="tokenomics-card">
							<div className="tokenomics-card-title">
								TEAM ALLOCATION
							</div>
							<div className="divider"></div>
							<div className="tokenomics-card-text">
								18% of the total token supply is designated for
								the Proxy Network team to fund development,
								marketing, and operational expenses, enabling
								innovation and ensuring project sustainability.
							</div>
						</GradientBox>
					</div>

					<div className="tokenomics-card-item" data-aos="fade-up">
						<GradientBox className="tokenomics-card">
							<div className="tokenomics-card-title">
								Transparent Decision-Making
							</div>
							<div className="divider"></div>
							<div className="tokenomics-card-text">
								All tokenomics decisions and adjustments are
								openly documented and communicated to the
								community through official channels, cultivating
								trust and confidence among stakeholders and
								promoting collaborative governance.
							</div>
						</GradientBox>
					</div>

					<div
						className="tokenomics-card-item"
						data-aos="fade-up-left"
					>
						<GradientBox className="tokenomics-card">
							<div className="tokenomics-card-title">
								Continuous Improvement
							</div>
							<div className="divider"></div>
							<div className="tokenomics-card-text">
								The $PROXY tokenomics model is designed to evolve
								based on feedback, market dynamics, and emerging
								trends, with community involvement crucial for
								shaping its future and driving sustainable
								growth within the Proxy Network ecosystem.
							</div>
						</GradientBox>
					</div>
				</div>

				<div className="description" data-aos="zoom-in-down">
					The $PROXY tokenomics model serves as the foundation for the
					success and sustainability of the Proxy Network platform,
					incentivizing participation, supporting liquidity, and
					empowering community governance to create a dynamic and
					resilient decentralized ecosystem that benefits all
					stakeholders. Join us on this journey as we pioneer the
					future of decentralized finance with Proxy Network and the
					$PROXY token.
				</div>
			</div>

			<img
				className="tokenomics-bg"
				src={TokenomicsBg}
				alt="Tokenomics"
			/>
		</div>
	);
}
