import "./Hero.scss";
import ParticlesComponent from "./ParticlesComponent";

export default function Hero() {
	return (
		<div className="hero">
			<div className="hero-content content-wrapper-lg">
				<div className="hero-title">
					<span className="proxy" data-aos="fade-right">PROXY</span>
					<span className="network" data-aos="fade-left">NETWORK</span>
				</div>
				
				<ParticlesComponent id="particles" />
			</div>

			<div className="light light-1"/>
		</div>
	);
}
