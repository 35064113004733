import "./ContactUs.scss";
import Button from "../../components/shared/Button";
import about_us from "../../assets/images/contact-us.png";
import telegram from "../../assets/images/telegram.svg";
import x from "../../assets/images/x.svg";
import ParticlesComponent from "./ParticlesComponent";

export default function ContactUs() {
	return (
		<div className="contact-us">
			<div className="contact-us-content content-wrapper-lg">
				<div className="contact-us-row">
					<div className="contact-us-txt">
						<div className="small-title" data-aos="fade-up">GET IN TOUCH</div>

						<div className="title" data-aos="fade-up" data-aos-delay="100">
							Join Our Community
						</div>

						<div className="description" data-aos="fade-up" data-aos-delay="200">
							We are here to assist you with any questions,
							concerns, or feedback you may have. We look forward
							to hearing from you and assisting you in any way we
							can. Come join our community!
						</div>

						<div className="contact-us-btns" data-aos="fade-up" data-aos-delay="300">
							<Button className="contact-us-btn" href={"https://t.me/ProxyNetworkAI"}>
								<img
									className="telegram-icon"
									src={telegram}
									alt="Telegram"
								/>
								TELEGRAM
							</Button>

							<Button className="contact-us-btn" href={"https://x.com/proxynetworkorg?s=21"}>
								<img
									className="telegram-icon"
									src={x}
									alt="Telegram"
								/>
								X PLATFORM
							</Button>
						</div>
					</div>

					<div className="contact-us-img-wrapper">
						<div className="contact-us-particles">
							<ParticlesComponent id="particles2" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
