import "./Top.scss";

export default function Top() {
	return (
		<div className="tokenomics-top">
			<div className="tokenomics-top-content content-wrapper-lg">
				<div className="small-title" data-aos="fade-up">TOKENOMICS</div>
				<div className="title" data-aos="fade-up" data-aos-delay="100">
					Empowering Economies with Dynamic Tokenomics
				</div>
				<div className="description" data-aos="fade-up"data-aos-delay="200">
					The Proxy Network token ($PROXY) acts as the primary currency
					within the Proxy ecosystem, facilitating transactions and
					offering utility across various platform functions. Tax
					allocations from token transactions are strategically
					distributed to stimulate growth and development within the
					ecosystem, covering expenses for partnerships, ecosystem
					development, liquidity incentives, team support, and
					community rewards, thereby encouraging engagement and
					fostering ecosystem expansion.
				</div>
			</div>

			<div className="light light-1" />
		</div>
	);
}
