import "./EnhanceBlockchain.scss";

export default function EnhanceBlockchain() {
	return (
		<div className="enhance-blockchain">
			<div className="enhance-blockchain-content content-wrapper-lg">
				<div className="eb-small-title" data-aos="zoom-out-down">
					Privacy, Security, and Accessibility{" "}
				</div>
				<div className="eb-title" data-aos="fade-up">
					Enhance Your Blockchain Experience with Proxy Network
				</div>

				<p className="eb-description" data-aos="zoom-out-up">
					Proxy Network enhances blockchain interaction through
					features that prioritize privacy, security, and
					accessibility. Key offerings include Anonymix for anonymous
					data transmission and ProxyVPN for bypassing geographical
					restrictions. The AI-Powered Vision system detects security
					threats in real-time, while the Smart Contract Creator
					allows users to deploy contracts without coding, making
					blockchain accessible to a wider audience.
				</p>
			</div>
		</div>
	);
}
