import Header from "../../components/shared/Header";
import Footer from "../../components/shared/Footer";
import Hero from "./Hero";
import AboutUs from "./AboutUs";
import KeyFeatures from "./KeyFeatures";
import EnhanceBlockchain from "./EnhanceBlockchain";
import ContactUs from "./ContactUs";

export default function Home() {
	return (
		<>
			<Header />

			<Hero />

			<AboutUs />

			<KeyFeatures />

			<EnhanceBlockchain />

			<ContactUs />

			<Footer/>
		</>
	);
}
