import React from "react";
import "./GradientBox.scss";

export default function GradientBox({ children, className = null }) {
	return (
		<div className={`gradient-box ${className ?? ''}`}>
			{children}
		</div>
	);
}
