import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import Bottom from "./Bottom";
import Top from "./Top";

export default function Tokenomics() {
	return (
		<>
			<Header />

			<Top />

			<Bottom />

			<Footer />
		</>
	);
}
