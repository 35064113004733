import { Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import Tokenomics from "./Tokenomics/Tokenomics";
/* import RoadmapV1 from "./RoadmapV1/RoadmapV1"; */
import RoadmapV2 from "./RoadmapV2/RoadmapV2";
import 'aos/dist/aos.css';
import { useEffect } from "react";
import AOS from "aos";

export default function App() {
    useEffect(() => {
        AOS.init({
            duration: 1500,
        });
    }, []);
    
    return <>
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/roadmap" element={<RoadmapV2 />} />
            {/* <Route path="/roadmap-v2" element={<RoadmapV2 />} /> */}
            <Route path="/tokenomics" element={<Tokenomics />} />
        </Routes>
    </>
}
