import "./AboutUs.scss";
import Button from "../../components/shared/Button";
import esphere from "../../assets/images/esphere.svg";
import about_us from "../../assets/images/about-us.png";

export default function Hero() {
	return (
		<div className="about-us" id="about">
			<div className="about-us-content content-wrapper-lg">
				<div className="about-us-row">
					<div className="about-us-img-wrapper">
						<img
							className="esphere"
							src={esphere}
							alt="Esphere"
							data-aos="fade-right"
						/>
						<img
							className="about-us-img"
							src={about_us}
							alt="About Us"
						/>
					</div>

					<div className="about-us-txt">
						<div className="small-title" data-aos="fade-up">
							WHO WE ARE
						</div>

						<div
							className="title"
							data-aos="fade-up"
							data-aos-delay="100"
						>
							Your Gateway to Secure and Unrestricted
							<span className="highlited-text-color">
								{" "}
								Blockchain{" "}
							</span>
							Access
						</div>

						<div
							className="description"
							data-aos="fade-up"
							data-aos-delay="200"
						>
							Unlock the full potential of blockchain technology
							with Proxy Network, your go-to tool for secure,
							private, and unrestricted access to blockchain
							networks and decentralized applications (dApps).
							Whether you're a blockchain enthusiast, developer,
							or business owner, Proxy Network offers the features
							you need to navigate the crypto space safely and
							efficiently.
						</div>

						<div
							className="about-us-btns"
							data-aos="fade-up"
							data-aos-delay="300"
						>
							<Button className="white-paper-btn" href={"https://proxy-network-organization.gitbook.io/proxynetwork/"}>
								WHITE PAPER
							</Button>
						</div>
					</div>
				</div>
			</div>

			<div className="light light-2" />
		</div>
	);
}
