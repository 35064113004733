import "./Footer.scss";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import GitBook from '../../assets/icons/gitbook.js';
import Telegram from '../../assets/icons/telegram.js';
import XLogo from '../../assets/icons/X_logo.js';

export default function Footer() {
	return (
		<div className="footer">
			<div className="footer-content content-wrapper-lg">
				<div className="footer-row">
					<div className="footer-about">
						<NavLink to="/">
							<img
								src={logo}
								alt="Logo"
								className="footer-logo"
							/>
						</NavLink>

						<div className="footer-about-title">PROXY NETWORK</div>

						<div className="footer-about-text">
							At Proxy Network, we specialize in providing
							cutting-edge solutions and expert guidance to
							individuals and businesses seeking to harness the
							power of blockchain for their ventures.
						</div>
					</div>

					<nav className="footer-nav">
						<div className="footer-nav-title">Quick Links</div>

						<ul className="footer-nav-links">
							<li>
								<NavLink to="/">Home</NavLink>
							</li>
							<li>
								<a href="/#about">About</a>
							</li>
							<li>
								<a href="/#features">Features</a>
							</li>
							<li>
								<NavLink to="/roadmap">Roadmap</NavLink>
							</li>
							<li>
								<NavLink to="/tokenomics">Tokenomics</NavLink>
							</li>
						</ul>
					</nav>
				</div>

				<div className="footer-bottom">
					<div className="copyright">
						© 2024 ProxyNetwork. All rights reserved.
					</div>

					<ul className="socials">
						<li className="social-item">
							<a href="https://t.me/ProxyNetworkAI" target="_blank" rel="noopener noreferrer">
								<Telegram/>
							</a>
						</li>
						<li className="social-item">
							<a href="https://proxy-network-organization.gitbook.io/proxynetwork/" target="_blank" rel="noopener noreferrer">
								<GitBook/>
							</a>
						</li>
						<li className="social-item">
							<a href="https://x.com/proxynetworkorg?s=21" target="_blank" rel="noopener noreferrer">
								<XLogo/>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}