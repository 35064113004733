import Footer from "../../components/shared/Footer";
import Header from "../../components/shared/Header";
import "./RoadmapV2.scss";
import Roadmap from "../../assets/images/roadmap-v2.svg";
import RoadmapMobile from "../../assets/images/roadmap-mobile-v2.svg";

export default function RoadmapV2() {
	return (
		<>
			<Header />

			<div className="roadmap-v2">
				<div className="roadmap-content content-wrapper-lg">
					<div className="small-title" data-aos="fade-up">Roadmap</div>
					<div className="title" data-aos="fade-up" data-aos-delay="100">
						Our Process is Simple Yet Proven
					</div>

					<div className="roadmap-graph-wrapper">
						<img
							src={Roadmap}
							alt="Roadmap"
							className="roadmap-img"
							width={843}
							height={1036}
						/>
						<img
							src={RoadmapMobile}
							alt="Roadmap"
							className="roadmap-img-mobile"
							width={400}
							height={1670}
						/>
					</div>
				</div>

				<div className="light light-1" />
				<div className="light light-2" />
			</div>

			<Footer />
		</>
	);
}
