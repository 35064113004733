import "./Header.scss";
import Button from "./Button";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ChartCircle, Home, InfoCircle, MagicStar, Map1 } from "iconsax-react";
import GitBook from "../../assets/icons/gitbook.js";
import Telegram from "../../assets/icons/telegram.js";
import XLogo from "../../assets/icons/X_logo.js";
import { useState } from "react";

export default function Header() {
	/* sidebar active */
	const [sidebarActive, setSidebarActive] = useState(false);
	const [isHovered, setIsHovered] = useState(false);

	const toggleSidebar = () => {
		setSidebarActive(!sidebarActive);
	};

	const closeSidebar = () => {
		setSidebarActive(false);
	};

	return (
		<div className="header">
			<div className="header-content content-wrapper-lg">
				<div className="header-left">
					<NavLink to="/">
						<img src={logo} alt="Logo" className="header-logo" />
					</NavLink>

					<button className="sidebar-toggle" onClick={toggleSidebar}>
						<Menu />
					</button>
				</div>

				<div className="header-center">
					<nav>
						<ul className="header-nav">
							<li>
								<NavLink
									to="/"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									Home
								</NavLink>
							</li>
							<li>
								<a href="/#about">About</a>
							</li>
							<li>
								<a href="/#features">Features</a>
							</li>
							<li>
								<NavLink
									to="/roadmap"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									Roadmap
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/tokenomics"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									Tokenomics
								</NavLink>
							</li>
						</ul>
					</nav>
				</div>

				<div className="header-right">
					<Button
						className="launch-dapp-btn"
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						{isHovered ? "COMING SOON" : "DAPP"}
					</Button>
				</div>
			</div>

			{/* sidebar */}
			<div
				className={`sidebar-backdrop ${sidebarActive ? "active" : ""}`}
				onClick={closeSidebar}
			></div>
			<div className={`sidebar ${sidebarActive ? "active" : ""}`}>
				<div className="sidebar-content">
					<div className="sidebar-logo">
						<img src={logo} alt="Logo" />
						<span>PROXY NETWORK</span>
					</div>

					<nav className="sidebar-nav">
						<ul className="sidebar-nav-list">
							<li>
								<NavLink
									to="/"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									<Home color="currentColor" /> Home
								</NavLink>
							</li>
							<li>
								<a href="/#about">
									<InfoCircle color="currentColor" />
									About
								</a>
							</li>
							<li>
								<a href="/#features">
									<MagicStar color="currentColor" />
									Features
								</a>
							</li>
							<li>
								<NavLink
									to="/roadmap"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									<Map1 color="currentColor" />
									Roadmap
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/tokenomics"
									className={({ isActive }) =>
										isActive ? "active" : ""
									}
								>
									<ChartCircle color="currentColor" />
									Tokenomics
								</NavLink>
							</li>
						</ul>
					</nav>

					<div className="sidebar-footer">
						<div className="copyright">
							© 2024 All rights reserved.
						</div>

						<ul className="socials">
							<li className="social-item">
								<a href="/">
									<Telegram />
								</a>
							</li>
							<li className="social-item">
								<a href="/">
									<GitBook />
								</a>
							</li>
							<li className="social-item">
								<a href="/">
									<XLogo />
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
